import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

const ROSALYN_BLUE = '#1b65e2';

@Injectable({
  providedIn: 'root',
})
export class ThemingService implements OnDestroy {
  private ngDestroy$ = new Subject();
  private primaryColor = ROSALYN_BLUE;

  public ngOnDestroy(): void {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  public async initialize(): Promise<void> {
    this.setColorVariable(this.primaryColor);
    return Promise.resolve();
  }

  private setColorVariable(color: string): void {
    document.documentElement.style.setProperty('--primary-color', color);
  }
}
