import { Component, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import {
  Validators,
  UntypedFormGroup,
  UntypedFormBuilder,
  AbstractControl,
} from '@angular/forms';
import { emailSuffixValidator } from '@modules/shared/validators/emailSuffix.validator';
import { Store } from '@ngrx/store';
import { AppState } from '@store/reducers';
import { forgotPassword } from '@store/actions/auth.actions';
import { getSendingRecoveryEmail } from '@store/selectors';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  public formGroup: UntypedFormGroup = this.formBuilder.group({
    email: [null, [Validators.required, Validators.email, emailSuffixValidator()]],
  });

  public showEmailSentMessage = false;
  public sendingRecoveryEmail$ = this.store.select(getSendingRecoveryEmail);

  get email(): AbstractControl {
    return this.formGroup.get('email');
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<AppState>,
    private location: Location,
  ) { }

  public submit(): void {
    if (this.formGroup.valid) {
      this.showEmailSentMessage = true;
      this.store.dispatch(forgotPassword({ email: this.email.value }));
    }
  }

  public redirectBack(): void {
    this.location.back();
  }
}
