<auth-base-container title="Log-in">
  <form
    class="form"
    [formGroup]="loginForm"
    [@.disabled]="true"
    (submit)="submit()"
    content
  >
    <mat-form-field
      floatLabel="always"
      appearance="fill"
      class="text-input"
      data-id="email"
      hideRequiredMarker
    >
      <mat-label>
        <span>Email</span>
      </mat-label>
      <input
        matInput
        placeholder="you@example.com"
        formControlName="email"
        required
      >
      <mat-error *ngIf="email.errors?.required">
        Email is required
      </mat-error>
      <mat-error *ngIf="email.errors?.email">
        Wrong email format
      </mat-error>
    </mat-form-field>
    <mat-form-field
      floatLabel="always"
      appearance="fill"
      class="text-input"
      data-id="password"
      hideRequiredMarker
    >
      <mat-label>
        <span>Password</span>
      </mat-label>
      <input
        matInput
        formControlName="password"
        required
        placeholder="Your password here"
        type="password"
      >
      <mat-error *ngIf="password.errors?.required">
        Password is required
      </mat-error>
    </mat-form-field>

    <div class="submit">
      <ros-button
        type="submit"
        data-id="submit"
        [isDisabled]="loggingIn$ | async"
        size="Large"
        width="Wide"
        title="Log In"
      ></ros-button>

      <mat-spinner *ngIf="loggingIn$ | async" diameter="20">
      </mat-spinner>
    </div>
  </form>
  <a
    routerLink="/forgot-password"
    footer
  >
    <span>Forgot your password?</span>
    <span>Request a new one</span>
  </a>
</auth-base-container>