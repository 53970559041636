import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@store/reducers/index';
import { authActions } from '@store/actions';
import { getValidatingTOTPToken } from '@store/selectors';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { clearTOTPAuthToken } from '@store/actions/auth.actions';

@Component({
  selector: 'totp-auth-login',
  templateUrl: './totp-login.component.html',
  styleUrls: ['./totp-login.component.scss'],
})
export class TotpLoginComponent {
  public validationTOTPToken$ = this.store.select(getValidatingTOTPToken);

  public get totpToken(): AbstractControl {
    return this.totpForm.get('totpToken');
  }

  public totpForm: FormGroup = this.formBuilder.group({
    totpToken: ['', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(6),
      Validators.pattern('^[0-9]*$'),
    ]],
  });

  private totpValidated = false;
  private destroy$ = new Subject<void>();

  constructor(
    protected store: Store<AppState>,
    private formBuilder: FormBuilder,
    private actions$: Actions,
  ) {
    this.actions$.pipe(
      ofType(authActions.validateTOTPFail),
      takeUntil(this.destroy$),
    ).subscribe(() => {
      this.totpForm.reset();
    });

    this.actions$.pipe(
      ofType(authActions.validateTOTPSuccess),
      takeUntil(this.destroy$),
    ).subscribe(() => {
      this.totpValidated = true;
    });
  }

  public ngOnDestroy(): void {
    if (!this.totpValidated) {
      this.store.dispatch(clearTOTPAuthToken());
    }

    this.destroy$.next();
    this.destroy$.complete();
  }

  public submit(): void {
    this.store.dispatch(authActions.validateTOTP({ totpToken: this.totpToken.value }));
  }
}
