import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { AppState } from '@store/reducers/index';
import { authActions } from '@store/actions';
import { AuthService } from '@services/auth.service';
import { PendoService } from '@modules/shared/services/analytics/pendo.service';
import { FullStoryService } from './modules/shared/services/analytics/fullstory.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit {
  constructor(
    private store: Store<AppState>,
    private authService: AuthService,
    private location: Location,
    private fullStoryService: FullStoryService,
    private pendoService: PendoService,
  ) { }

  public ngOnInit(): void {
    this.initializeAnalytics();

    if (this.authService.getTokenFromSessionStorage()
     || this.authService.getTokensFromLocalStorage()) {
      this.store.dispatch(authActions.initLogin());
    }
  }

  private initializeAnalytics(): void {
    this.fullStoryService.initialize();
    this.pendoService.initialize();
  }
}
