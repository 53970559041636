import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'auth-base-container',
  templateUrl: './auth-base-container.component.html',
  styleUrls: ['./auth-base-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AuthBaseContainerComponent {
  @Input() public title = '';
}
