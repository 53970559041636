import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminModule } from '@modules/admin/admin.module';
import { ResultsModule } from '@modules/results/results.module';
import { AuthGuard } from '@guards/auth.guard';
import { AuthLoginComponent } from '@modules/auth/components/auth-login/auth-login.component';
import { NonAuthGuard } from '@guards/non-auth.guard';
import { AuthModule } from './modules/auth/auth.module';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'admin',
    pathMatch: 'full',
  },
  {
    path: 'admin',
    children: [
      {
        path: '',
        component: AuthLoginComponent,
        canActivate: [NonAuthGuard],
      },
      {
        path: '',
        loadChildren: (): Promise<typeof AdminModule> => import('./modules/admin/admin.module').then(
          (m) => m.AdminModule,
        ),
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    loadChildren: (): Promise<typeof AuthModule> => import('./modules/auth/auth.module').then(
      (m) => m.AuthModule,
    ),
    canActivate: [NonAuthGuard],
    path: '',
  },
  {
    loadChildren: (): Promise<typeof ResultsModule> => import('./modules/results/results.module').then(
      (m) => m.ResultsModule,
    ),
    path: 'results',
  },
  {
    path: '**',
    redirectTo: 'admin',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
