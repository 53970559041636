import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthState } from '@store/reducers/auth.reducer';
import { getAwaitingMFA } from '@store/selectors';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MFAGuard implements CanActivate {
  private awaitingMFA$: Observable<boolean> = this.store.select(getAwaitingMFA);

  constructor(
    private store: Store<AuthState>,
    private router: Router,
  ) {}

  public canActivate(): Observable<boolean> {
    return this.awaitingMFA$.pipe(
      map((awaitingMFA) => {
        if (awaitingMFA) {
          return true;
        }
        this.router.navigate(['/admin/']);
        return false;
      }),
    );
  }
}
