import { ValidatorFn, UntypedFormControl } from '@angular/forms';

export function minMaxTrimmedStringLengthValidator(min: number, max: number): ValidatorFn {
  return (control: UntypedFormControl): { [key: string]: any } | null => {
    const { value } = control;

    if (value && value !== '') {
      const trimmed = control.value.trim().length;

      if (min && trimmed < min) {
        return { minlength: true };
      } if (max && trimmed > max) {
        return { maxlength: true };
      }
    }
    return null;
  };
}
