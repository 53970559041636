<div class="auth">
  <div class="auth__header auth-header">
    <img
      src="assets/img/rosalyn-logo.svg"
      class="auth-header__logo"
      alt="Rosalyn Logo"
    >
    <h1 class="auth-header__title">{{title}}</h1>
  </div>
  <div class="auth__content" select="[content]">
    <ng-content></ng-content>
  </div>
  <footer class="auth__footer">
    <ng-content select="[footer]"></ng-content>
  </footer>
</div>