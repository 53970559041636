<auth-base-container title="Authentication code">
  <form
    class="form"
    [formGroup]="totpForm"
    [@.disabled]="true"
    (submit)="submit()"
    content
  >
    <span class="description">
      Please provide the code from your authenticator app. Recovery codes can be used if you've lost access to your
      device.
    </span>
    <mat-form-field
      floatLabel="always"
      appearance="fill"
      class="text-input"
      data-id="password"
      hideRequiredMarker
    >
      <mat-label>
        <span>Authentication code</span>
      </mat-label>
      <input
        matInput
        id="totpInput"
        type="text"
        minlength="6"
        maxlength="6"
        formControlName="totpToken"
        placeholder="Your authentication code here"
        inputmode="numeric"
        autocomplete="one-time-code"
        numbersOnly
        required
      >
      <mat-error *ngIf="totpToken.touched && totpToken.errors?.required">
        Authentication code is required
      </mat-error>
      <mat-error *ngIf="totpToken.touched && (totpToken.errors?.minlength || totpToken.errors?.maxlength)">
        Authentication code must be exactly 6 digits
      </mat-error>
    </mat-form-field>
    <div class="submit">
      <ros-button
        type="submit"
        data-id="submit"
        [isDisabled]="(validationTOTPToken$ | async) || !totpForm.valid"
        size="Large"
        width="Wide"
        title="Verify code"
      ></ros-button>
      <mat-spinner  
        *ngIf="validationTOTPToken$ | async"
        diameter="20">
      </mat-spinner>
    </div>
  </form>
  
  <a
    routerLink="/admin"
    footer
  >
    <span>Want to Log in?</span>
    <span>Take me back</span>
  </a>
</auth-base-container>
