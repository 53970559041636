import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@store/reducers/index';
import { authActions } from '@store/actions';
import { getLoggingIn } from '@store/selectors';
import { LoginCredentials } from '@models/auth.model';
import { ActivatedRoute } from '@angular/router';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { emailSuffixValidator } from '@modules/shared/validators/emailSuffix.validator';

@Component({
  selector: 'app-auth-login',
  templateUrl: './auth-login.component.html',
  styleUrls: ['./auth-login.component.scss'],
})
export class AuthLoginComponent {
  public loggingIn$ = this.store.select(getLoggingIn);
  public loginForm: FormGroup = this.formBuilder.group({
    email: [null, [Validators.required, Validators.email, emailSuffixValidator()]],
    password: [null, [Validators.required]],
  });

  public get email(): AbstractControl {
    return this.loginForm.get('email');
  }

  public get password(): AbstractControl {
    return this.loginForm.get('password');
  }

  constructor(
    protected store: Store<AppState>,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
  ) { }

  public handleLogin(loginForm: LoginCredentials): void {
    const { redirectPath } = this.activatedRoute.snapshot.queryParams;

    this.store.dispatch(authActions.login({ loginForm, redirectPath }));
  }

  public submit(): void {
    if (this.loginForm.valid) {
      const { redirectPath } = this.activatedRoute.snapshot.queryParams;

      this.store.dispatch(authActions.login({ loginForm: { ...this.loginForm.value }, redirectPath }));
    }
  }
}
