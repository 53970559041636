

<div class="container">
   <div class="title">
    {{dialogData.text}}
   </div>
   <div class="actions">
      <ros-button 
                  *ngIf='dialogData.withDenyButton'
                  (onClick)="deny()"
                  width="MediumShort"
                  variant="Ternary"
                  type="Info"
                  title="No">
      </ros-button>
      <ros-button (onClick)="confirm()"
                  width="MediumShort"
                  [title]="dialogData.withDenyButton ? 'Yes' : 'Ok'">
      </ros-button>
   </div>
</div>
