
<auth-base-container title="Create New Password">
  <form
    *ngIf="!showSetPasswordSuccessMessage"
    class="form"
    [@.disabled]="true"
    [formGroup]="formGroup"
    (submit)="submit()"
    content
  >
    <mat-form-field
    floatLabel="always"
    appearance="fill"
    class="text-input"
    [class.form-field--valid]="!passwordCheckPending && password.status === 'VALID'"
    hideRequiredMarker
    >
    <mat-label>
      <span>Password</span>
    </mat-label>
      <input
        matInput
        formControlName="password"
        [type]="hidePassword ? 'password' : 'text'"
        required
      >
      <app-password-strength
        matSuffix
        class="password-strength"
        [password]="password.value"
      >
      </app-password-strength>
      <button
        mat-icon-button
        matSuffix
        (click)="toggleHidePassword({event: $event, isPasswordConfirm: false})"
      >
        <mat-icon>{{hidePassword ? "visibility_off" : "visibility"}}</mat-icon>
      </button>
      <mat-error *ngIf="password.errors?.required">
        Passwords is required
      </mat-error>
      <mat-error *ngIf="password.errors?.minlength">
        Passwords has to have 8 or more characters
      </mat-error>
      <mat-error *ngIf="password.errors?.maxlength">
        Passwords has to have 64 or less characters
      </mat-error>
      <mat-error *ngIf="password.valid">
        Passwords don't match the criteria
      </mat-error>
      <mat-error *ngIf="password.errors?.password">
        {{ password.errors?.password}}
      </mat-error>
    </mat-form-field>

    <mat-form-field
      floatLabel="always"
      appearance="fill"
      class="text-input"
      [class.form-field--valid]="confirmPassword.status === 'VALID'"
      hideRequiredMarker
    >
      <mat-label>
        <span>Confirm Password</span>
      </mat-label>
      <input
        matInput
        formControlName="confirmPassword"
        required
        [type]="hideConfirmPassword ? 'password' : 'text'"
      >
      <button
        mat-icon-button
        matSuffix
        (click)="toggleHidePassword({event: $event, isPasswordConfirm: true})"
      >
        <mat-icon>{{hideConfirmPassword ? "visibility_off" : "visibility"}}</mat-icon>
      </button>
      <mat-error *ngIf="confirmPassword.errors?.required">
        Confirming passwords is required
      </mat-error>
      <mat-error *ngIf="confirmPassword.errors?.differentPasswords">
        Passwords don't match
      </mat-error>
    </mat-form-field>

    <div class="submit">
      <ros-button
        title="Update Password"
        type="submit"
        size="Large"
        width="Wide"
        class="submit__button"
        [isDisabled]="formGroup.disabled || (settingPassword$ | async)"
      >
      </ros-button>
      <mat-spinner
        *ngIf="(settingPassword$ | async)"
        diameter="20">
      </mat-spinner>
    </div>
  </form>
  <div class="set-password-success" *ngIf="showSetPasswordSuccessMessage" content>
    <span class="set-password-success__header">Success</span>
    <span class="set-password-success__message">Your password has been {{resetMode ? 'reset' : 'set'}}. </span>
    <ros-button title="Back To Login"
                routerLink="/admin"
                size="Large"
                width="Wide">
    </ros-button>
  </div>
</auth-base-container>
