import {
  Directive,
  HostListener,
  Input,
  ElementRef,
} from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[numbersOnly]',
})
export class NumbersOnlyDirective {
  @Input() public ngControl: NgControl;

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  public onInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    const inputValueWithOnlyNumbers = input.value.replace(/[^0-9]/g, '');

    if (input.value !== inputValueWithOnlyNumbers) {
      input.value = inputValueWithOnlyNumbers;
      this.ngControl?.control?.setValue(inputValueWithOnlyNumbers, { emitEvent: false });
    }
  }
}
