import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthState } from '@store/reducers/auth.reducer';
import {
  getLoggedIn,
  getLoggingIn,
} from '@store/selectors';
import { Observable } from 'rxjs';
import {
  filter,
  map,
  switchMap,
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NonAuthGuard implements CanActivate {
  private loggedIn$: Observable<boolean> = this.store.select(getLoggedIn);
  private loggingIn$: Observable<boolean> = this.store.select(getLoggingIn);

  constructor(
    private store: Store<AuthState>,
    private router: Router,
  ) {}

  public canActivate(): Observable<boolean> {
    return this.loggingIn$.pipe(
      filter((loggingIn) => !loggingIn),
      switchMap(() => (this.loggedIn$)),
      map((loggedIn) => {
        if (!loggedIn) {
          return true;
        }
        this.router.navigate(['/admin/my-account']);
        return false;
      }),
    );
  }
}
