export interface Tokens {
  token: string;
  refreshToken: string;
}

export enum TokenType {
  EMAIL_VERIFICATION = 'emailVerificationToken',
  PASSWORD_RESET = 'passwordResetToken'
}

export type RoleValues =
  'accAdmin'
  | 'certManager'
  | 'author'
  | 'proctor'
  | 'externalProctor'
  | 'scorer'
  | 'learner'
  | 'sysAdmin';

export interface Role {
  value: RoleValues,
  description: string,
}

export interface UserData {
  id?: number;
  firstName: string;
  lastName: string;
  email: string;
  roles: string[];
  avatar: string;
  organization: {
    name: string,
    id: number,
    reference: string
  };
  testingPlatform: string;
  mobile?: string;
  city?: string;
  country?: string;
  MFAenabled: boolean;
}
export enum LoginResponseTypes{
  MFA = 'MFA',
  FULL ='FULL'
}
export interface BaseLoginResponse {
  type: LoginResponseTypes
}

export interface MFATokenResponse extends BaseLoginResponse {
  type: LoginResponseTypes.MFA;
  mfaToken: string;
}

export interface FullLoginResponse extends BaseLoginResponse, Tokens {
  type: LoginResponseTypes.FULL;
  user: UserData;
}

export type LoginResponse = MFATokenResponse | FullLoginResponse;

export interface RefreshTokenResponse extends Tokens {
  error: string | null
}

export interface ForgotPasswordResponse {
  message: string;
}

export interface LoginCredentials {
  email: string;
  password: string;
}

export interface UserTokenData {
  id: number,
  email: string,
  firstName: string,
  lastName: string,
}

export interface GeneratedTOTPData {
  totpUrl: string;
  totpSecret: string;
}

export interface SignUpForm {
  firstName: string,
  lastName: string,
  email: string,
}
