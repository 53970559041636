import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@store/reducers/index';
import { authActions } from '@store/actions';
import { getLoggingIn } from '@store/selectors';
import {
  UntypedFormGroup, Validators, UntypedFormBuilder, AbstractControl,
} from '@angular/forms';
import { emailSuffixValidator } from '@modules/shared/validators/emailSuffix.validator';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-learners-login',
  templateUrl: './learners-login.component.html',
  styleUrls: ['./learners-login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LearnersLoginComponent implements OnInit {
  public get email(): AbstractControl {
    return this.formGroup.get('email');
  }

  public get password(): AbstractControl {
    return this.formGroup.get('password');
  }

  public loggingIn$ = this.store.select(getLoggingIn);

  public formGroup: UntypedFormGroup = this.formBuilder.group({
    email: [null, [Validators.required, Validators.email, emailSuffixValidator()]],
    password: [null, [Validators.required]],
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<AppState>,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) { }

  public ngOnInit(): void {
    this.router.navigateByUrl('/admin');
  }

  public submit(): void {
    const { redirectPath } = this.activatedRoute.snapshot.queryParams;

    if (this.formGroup.valid) {
      this.store.dispatch(authActions.login({ loginForm: this.formGroup.value, redirectPath }));
    }
  }
}
