import { Component, ViewEncapsulation } from '@angular/core';
import {
  UntypedFormBuilder,
  AbstractControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { getSigningUp } from '@store/selectors';
import { Store } from '@ngrx/store';
import { AppState } from '@store/reducers';
import { minMaxTrimmedStringLengthValidator } from '@shared/validators/minMaxTrimmedStringLength.validator';
import { emailSuffixValidator } from '@modules/shared/validators/emailSuffix.validator';
import { signUp } from '@store/actions/auth.actions';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SignUpComponent {
  get firstName(): AbstractControl {
    return this.formGroup.get('firstName');
  }

  get lastName(): AbstractControl {
    return this.formGroup.get('lastName');
  }

  get email(): AbstractControl {
    return this.formGroup.get('email');
  }

  public formGroup: UntypedFormGroup = this.formBuilder.group({
    firstName: [null, [
      Validators.required,
      minMaxTrimmedStringLengthValidator(1, 90),
    ]],
    lastName: [null, [
      Validators.required,
      minMaxTrimmedStringLengthValidator(1, 90),
    ]],
    email: [null, [Validators.required, Validators.email, emailSuffixValidator()]],
  });

  public terms = false;

  public signingUp$ = this.store.select(getSigningUp);

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<AppState>,
  ) { }

  public submit(): void {
    if (this.formGroup.valid) {
      this.store.dispatch(signUp({ form: this.formGroup.value }));
    }
  }
}
