import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ErrorResponse } from '@models/response.model';
import { AuthService } from '@services/auth.service';
import { RedirectService } from '@services/redirect.service';
import { FullStoryService } from '@modules/shared/services/analytics/fullstory.service';
import {
  UserData,
  LoginResponse,
  LoginResponseTypes,
  MFATokenResponse,
  FullLoginResponse,
} from '@models/auth.model';
import { PendoService } from '@modules/shared/services/analytics/pendo.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfirmDialogService } from '@services/confirm-dialog.service';
import { authActions } from '../actions';
import { AuthState } from '../reducers/auth.reducer';
import { getRedirectPath } from '../selectors';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private redirectService: RedirectService,
    private snackBar: MatSnackBar,
    private store: Store<AuthState>,
    private readonly fullStoryService: FullStoryService,
    private readonly pendoService: PendoService,
    private readonly router: Router,
    private confirmDialogService: ConfirmDialogService,
  ) { }

  private loginActionsMap = {
    [LoginResponseTypes.MFA]: (loginResponse: MFATokenResponse) => authActions.loginFirstStepSuccess({ loginResponse }),
    [LoginResponseTypes.FULL]: (loginResponse: FullLoginResponse) => authActions.loginSuccess({ loginResponse }),
  } as const;

  public login$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.login),
    switchMap(({ loginForm: { email, password } }) => this.authService.login({ email, password }).pipe(
      map((response: LoginResponse) => {
        switch (response.type) {
          case LoginResponseTypes.MFA:
            return this.loginActionsMap[LoginResponseTypes.MFA](response);
          case LoginResponseTypes.FULL:
            return this.loginActionsMap[LoginResponseTypes.FULL](response);
          default:
            throw new Error('Unexpected login response type');
        }
      }),
      catchError(({ error }: { error: ErrorResponse}) => {
        this.snackBar.open(error?.message || 'Unexpected authentication error', null, { duration: 2000 });
        return of(authActions.loginFail());
      }),
    )),
  ));

  public loginSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.loginSuccess, authActions.validateTOTPSuccess),
    withLatestFrom(
      this.store.select(getRedirectPath),
    ),
    tap(([{ loginResponse }, redirectPath]) => {
      const { token, refreshToken, user: { roles } } = loginResponse;

      this.authService.setTokensInSessionStorage(token, refreshToken);

      this.identifyUserForAnalytics(loginResponse.user);

      if (redirectPath) {
        this.router.navigateByUrl(redirectPath);
        return;
      }

      this.redirectService.redirectByRole(roles);
    }),
  ), { dispatch: false });

  public loginFirstStepSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.loginFirstStepSuccess),
    tap(({ loginResponse }) => {
      this.router.navigate(['authentication-code']);

      this.authService.setTokensInSessionStorage(loginResponse.mfaToken, '');
    }),
  ), { dispatch: false });

  public logout$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.logout),
    switchMap(() => this.authService.logout(this.authService.getTokenFromSessionStorage()).pipe(
      map(() => {
        authActions.logoutSuccess();
        this.authService.cleanStorage();
        this.redirectService.redirectByUrl('login', 'admin');
      }),
      catchError(() => of(authActions.logoutFail())),
    )),
  ), { dispatch: false });

  public expiredTokenLogout$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.expiredTokenLogout),
    tap(() => {
      this.authService.cleanStorage();
      this.redirectService.redirectByUrl('login', 'admin');
      this.snackBar.open('Your session has expired', null, { duration: 3000 });
    }),
  ), { dispatch: false });

  public initLogin$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.initLogin),
    switchMap(() => (this.authService.initLogin()).pipe(
      map((loginResponse) => authActions.initLoginSuccess({ loginResponse })),
      catchError(() => {
        this.redirectService.redirectByUrl('login', 'admin');
        this.snackBar.open('Your session has expired', null, { duration: 3000 });
        this.authService.cleanStorage();
        return of(authActions.initLoginFail());
      }),
    )),
  ));

  public setPassword$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.setPassword),
    switchMap(({ token, newPassword }) => (
      this.authService.setPassword(token, newPassword).pipe(
        map((loginResponse) => {
          this.snackBar.open('Password set successfully', null, { duration: 2000 });
          return authActions.setPasswordSuccess({ loginResponse });
        }),
        catchError(() => {
          this.snackBar.open('Failed to set password', null, { duration: 2000 });
          return of(authActions.setPasswordFail());
        }),
      )
    )),
  ));

  public changePassword$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.changePassword),
    switchMap(({ currentPassword, password }) => (
      this.authService.changePassword(currentPassword, password).pipe(
        map(() => {
          this.snackBar.open('Password has changed successfully', null, { duration: 2000 });
          return authActions.changePasswordSuccess();
        }),
        catchError(({ error }: { error: ErrorResponse }) => {
          this.snackBar.open(error.message, null, { duration: 2000 });
          return of(authActions.changePasswordFail());
        }),
      )
    )),
  ));

  public generateTOTPData$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.generateTOTPData),
    exhaustMap(() => (
      this.authService.generateTOTPData().pipe(
        map(({ totpUrl, totpSecret }) => authActions.generateTOTPDataSuccess({ totpUrl, totpSecret })),
        catchError(() => {
          this.snackBar.open(
            'Failed to enable multi factor authentication. Try again later.',
            null,
            { duration: 2000 },
          );
          return of(authActions.generateTOTPDataFail());
        }),
      )
    )),
  ));

  public confirmTOTP$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.confirmTOTP),
    exhaustMap(({ totpToken }) => (
      this.authService.confirmTOTP(totpToken).pipe(
        map(() => authActions.confirmTOTPSuccess()),
        catchError(() => of(authActions.confirmTOTPFail())),
      )
    )),
  ));

  public disableTOTP$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.disableTOTP),
    exhaustMap(() => (
      this.authService.disableTOTP().pipe(
        map(() => {
          this.snackBar.open('Multi factor authentication disabled', null, { duration: 2000 });
          return authActions.disableTOTPSuccess();
        }),
        catchError(() => {
          this.snackBar.open(
            'Disabling multi factor authentication failed. Try again later.',
            null,
            { duration: 2000 },
          );
          return of(authActions.disableTOTPFail());
        }),
      )
    )),
  ));

  public validateTOTP$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.validateTOTP),
    exhaustMap(({ totpToken }) => (
      this.authService.validateTOTP(totpToken).pipe(
        map((loginResponse) => authActions.validateTOTPSuccess({ loginResponse })),
        catchError((err) => {
          if (err instanceof HttpErrorResponse && err.status === 429) {
            this.confirmDialogService.confirm({
              // eslint-disable-next-line max-len
              text: 'To protect your account, we\'ve temporarily paused authentication attempts. Please wait 5 minutes and try again.',
              width: 400,
              withDenyButton: false,
            }).subscribe((data) => {
              if (data) {
                this.router.navigate(['admin']);
              }
            });

            return of(
              authActions.clearTOTPAuthToken(),
              authActions.validateTOTPFail(),
            );
          }
          this.snackBar.open('Invalid authentication code', null, { duration: 2000 });

          return of(authActions.validateTOTPFail());
        }),
      )
    )),
  ));

  public clearTOTPAuthToken$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.clearTOTPAuthToken),
    map(() => {
      this.authService.cleanStorage();
      return authActions.clearTOTPAuthTokenSuccess();
    }),
  ));

  public resetPassword$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.resetPassword),
    exhaustMap(({ token, newPassword }) => (
      this.authService.resetPassword(token, newPassword).pipe(
        map((loginResponse) => {
          this.snackBar.open('Password set successfully', null, { duration: 2000 });
          return authActions.setPasswordSuccess({ loginResponse });
        }),
        catchError(() => {
          this.snackBar.open('Failed to set password', null, { duration: 2000 });
          return of(authActions.setPasswordFail());
        }),
      )
    )),
  ));

  public fetchUserTokenData$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.fetchUserTokenData),
    switchMap(({ token, tokenType }) => (
      this.authService.fetchUserTokenData(token, tokenType).pipe(
        map((userTokenData) => authActions.fetchUserTokenDataSuccess({ userTokenData })),
        catchError(({ error }: { error: ErrorResponse }) => {
          this.snackBar.open(error.message, null, { duration: 2000 });
          return of(authActions.fetchUserTokenDataFail());
        }),
      )
    )),
  ));

  public forgotPassword$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.forgotPassword),
    switchMap(({ email }) => this.authService.forgotPassword(email).pipe(
      map(() => authActions.forgotPasswordSuccess()),
      catchError(() => of(authActions.forgotPasswordFail())),
    )),
  ));

  public editMyAccount$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.editMyAccount),
    switchMap(({ userData }) => (
      this.authService.editMyAccount(userData).pipe(
        map(() => {
          this.snackBar.open('Profile updated successfully', null, { duration: 2000 });
          return authActions.editMyAccountSuccess({ userData });
        }),
        catchError(({ error }: { error: ErrorResponse }) => {
          this.snackBar.open(error.message, null, { duration: 2000 });
          return of(authActions.editMyAccountFail());
        }),
      )
    )),
  ));

  public signUp$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.signUp),
    switchMap(({ form }) => (
      this.authService.signUp(form).pipe(
        map(() => {
          this.snackBar.open('Your account has been created, please check your email to set you password',
            null,
            { duration: 2000 });
          return authActions.signUpSuccess();
        }),
        catchError(({ error }: { error: ErrorResponse }) => {
          this.snackBar.open(error.message, null, { duration: 2000 });
          return of(authActions.signUpFail());
        }),
      )
    )),
  ));

  private identifyUserForAnalytics(data: UserData): void {
    [
      this.fullStoryService,
      this.pendoService,
    ].forEach((service) => service.identifyUser(data));
  }
}
