import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MFAGuard } from '@guards/mfa.guard';
import { SetPasswordComponent } from './components/set-password/set-password.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { TotpLoginComponent } from './components/totp-login/totp-login.component';

const routes: Routes = [
  {
    path: 'set-password/:token',
    component: SetPasswordComponent,
  },
  {
    path: 'reset-password/:token',
    component: SetPasswordComponent,
    data: { mode: 'reset' },
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'authentication-code',
    component: TotpLoginComponent,
    canActivate: [MFAGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule { }
